/** @jsxImportSource @emotion/react */
import Page from "./Page";
import { css } from '@emotion/react';

const style = css`

   height: var(--window-height);
   background-color: teal;

   @media (min-width: 600px) {
      background-color: red;
   }

   @media (min-width: 900px) {
      background-color: green;
   }

   @media (min-width: 1200px) {
      background-color: blue;
   }

   @media (min-width: 1536px) {
      background-color: purple;
   }
`;


class Test extends Page {
   _render() {
      return <div css={style}>

      </div>
   }
}

export default Test;