
import './App.css';
import './fonts.css';
import './animation.css';
import AppWrapper, { Route } from '@xavisoft/app-wrapper';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Test from './pages/Test';
import Ecommerce from './pages/Ecommerce';
import DigitalFirm from './pages/DigitalFirm';
import BotMaker from './pages/BotMaker';
import Footer from './components/Footer';


function setDimensions() {
  
  const width = window.innerWidth + 'px';
  const height = window.innerHeight + 'px';

  document.documentElement.style.setProperty('--window-width', width);
  document.documentElement.style.setProperty('--window-height', height);

}

window.addEventListener('resize', setDimensions);
setDimensions();


function App() {
  return (
    <AppWrapper>

      <Navbar />

      <Route path="/" component={Home} />
      <Route path="/test" component={Test} />
      <Route path="/ecommerce" component={Ecommerce} />
      <Route path="/digital-firm" component={DigitalFirm} />
      <Route path="/botmaker" component={BotMaker} />

      <Footer />
      
    </AppWrapper>
  );
}

export default App;
