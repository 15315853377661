import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import Component from "@xavisoft/react-component";
import { v4 as uuid } from 'uuid';
import { delay } from "../utils";


class Subscription extends Component {

   divId = 'div-' + uuid();

   createEmbed() {
      
      const div = document.getElementById(this.divId);
      let embed = div.querySelector('embed');
      
      if (embed)
         embed.remove();

      embed = document.createElement('embed');
      embed.src = 'https://docs.google.com/forms/d/e/1FAIpQLScFNJwIUrxdav49QNU0mqaL5Sp5fbq_PaezqJOB7yJBRxTqwQ/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link'
      embed.height = 'auto';
      embed.width = '100%';
      embed.style.aspectRatio = 1;
      
      div.append(embed);

   }

   async componentDidUpdate(prevProps) {
      
      if (this.props.open && !prevProps.open) {
         await delay(500);
         this.createEmbed();
      }

   }

   render() {
      return <Dialog open={this.props.open} fullScreen>
         <DialogContent>
            <div id={this.divId} />
         </DialogContent>

         <DialogActions>

            <Button variant="contained" onClick={this.createEmbed}>
               RELOAD
            </Button>

            <Button onClick={this.props.close}>
               CLOSE
            </Button>
         </DialogActions>
      </Dialog>
   }
}

export default Subscription;