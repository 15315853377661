import { Divider, Fab } from "@mui/material";
import Component from "@xavisoft/react-component";
import { HashLink as Link } from "react-router-hash-link";
import CloseIcon from '@mui/icons-material/Close';


class MobileNav extends Component {
   render() {
      return <div
         style={{
            position: 'fixed',
            '--top': '0px',
            top: 'var(--top)',
            left: 0,
            right: 0,
            height: 'calc(var(--window-height) - var(--top))',
            display: this.props.open ? 'block' : 'none',
            zIndex: 1,
         }}
         className="primary-bg"
      >
         <div style={{ margin: 30 }}>
            {
               this.props.links.map(link => {
                  return <>
                     <Link
                        to={link.to}
                        onClick={this.props.close}
                        style={{
                           fontFamily: "'SF Pro Text', sans-serif",
                           color: 'white',
                           margin: 10,
                           display: 'block',
                           textDecoration: 'none'
                        }}
                     >
                        {link.caption}
                     </Link>

                     <Divider />
                  </>
               })
            }
         </div>

         <div
            style={{
               position: 'absolute',
               bottom: 0,
               left: 0,
               right: 0,
               height: 80,
            }}
            className="h-align"
         >
            <Fab onClick={this.props.close}>
               <CloseIcon />
            </Fab>
         </div>
      </div>
   }
}


export default MobileNav;