
import Page from './Page';
import erpJPEG from '../media/img/erp.jpg';
import ComingSoon from '../components/ComingSoon';
import { MetaTags } from 'react-meta-tags';

class DigitalFirm extends Page {
   _render() {

      return <>
         <MetaTags>
            <title>Digital Firm | Xavisoft Digital</title>
            <meta 
               name="description" 
               content="We are developing a book-keeping system for small businesses, and we are planning to make it way cheaper than QuickBooks. Coming soon!"
            />
         </MetaTags>

         <ComingSoon
            title="Digital Firm"
            story="We are developing a book-keeping system for small businesses, and we are planning to make it way cheaper than QuickBooks. Get an email notification when we launch!"
            image={erpJPEG}
         />
      </>
   }
}


export default DigitalFirm;