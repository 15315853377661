


import Component from '@xavisoft/react-component';
import Grid from '@mui/material/Grid';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import { HashLink } from 'react-router-hash-link';
import { BOTMAKER_URL } from '../constants';
import Anchor from './Anchor';


const products = [
   { name: 'BotMaker', link: BOTMAKER_URL, externalLink: true },
   { name: 'Digital Firm', link: '/digital-firm' },
   { name: 'Online Shop', link: '/ecommerce' },
]


function ContactItem(props) {

	const { icon:Icon, href="#", caption, children } = props;

	return <a href={href} className="vh-align" style={{ fontSize: 16, color: 'white', textDecoration: 'none', margin: '10px auto' }}>
		<Icon />
		<span style={{ marginLeft: 10 }}>
			{caption}
			{children}
		</span>
	</a>
}


function SocialLink(props) {

	const { href='#', icon:Icon } = props;

	return <a href={href} style={{ color: 'white' }}>
		<Icon style={{ fontSize: 30, margin: 'auto 5px' }} />
	</a>
}


function SocialLinks(props) {

	return <div className="vh-align">
		{props.children}
	</div>
}


class Footer extends Component {

	render() {
		return <footer>

			<div style={{ color: 'white', padding: '20px 0', }} className="primary-bg">

				<div className="container">

					<Grid container spacing={4}>

						<Grid item xs={12} sm={6} style={{  background: 'redd' }}>


							<h2 style={{ textAlign: 'center', color: 'white' }}>Contact Info</h2>

							<ContactItem caption="+263 715 919 598" href="tel:+263715919598" icon={PhoneIcon} />
							<ContactItem caption="+263 715 919 598" href="https://wa.me/263715919598" icon={WhatsAppIcon} />
							<ContactItem caption="info@xavisoft.co.zw" href="mailto:info@xavisoft.co.zw" icon={EmailIcon} />
							<ContactItem caption="Xavisoft Digital" icon={FacebookIcon} href="https://facebook.com/XavisoftDigital" />

						</Grid>

						<Grid item xs={12} sm={6} style={{  textAlign: 'center' }}>

                     <div
                        className='h-align'
                     >
                        <div>

                           <h2 style={{ color: 'white' }}>Products</h2>

                           {
                              products.map(product => {

											let to, href, Component;

											if (product.externalLink) {
												href = product.link
												Component = Anchor
											} else {
												to = product.link
												Component = HashLink;
											}

                                 return <Component
												href={href}
												to={to}
                                    style={{
                                       display: 'block',
                                       color: 'white',
                                       margin: '10px auto',
                                       fontSize: 16,
                                       textUnderlineOffset: 5,
                                       textDecoration: 'none',
                                       fontWeight: 400
                                    }}
                                 >{product.name}</Component>
                              })
                           }
                        </div>
                     </div>

							<h2 className="center-align" style={{ color: 'white' }}>Social Links</h2>

							<SocialLinks>
								<SocialLink icon={FacebookIcon} href="https://facebook.com/XavisoftDigital" />
								<SocialLink icon={WhatsAppIcon} href="https://wa.me/263715919598" />
								<SocialLink icon={EmailIcon} href="mailto:info@xavisoft.co.zw" />
								<SocialLink icon={PhoneIcon} href="tel:+263715919598" />
							</SocialLinks>
						</Grid>

					</Grid>

				</div>
			</div>

			<div style={{ background: 'black', textAlign: 'center', color: 'white', padding: '20px' }}>
				<span style={{ fontSize: 16, fontFamily: 'sans-serif', paddingBottom: 10, display: 'inline-block' }}>
					&copy; { (new Date()).getFullYear() } Xavisoft Digital
				</span>
			</div>
		</footer>
	}
}

export default Footer;