
import Page from './Page';
import botmakerJPG from '../media/img/botmaker.jpg';
import { MetaTags } from 'react-meta-tags';
import { Button, Grid, Hidden } from '@mui/material';
import LazyloadImage from '../components/LazyloadImage';
import { BOTMAKER_URL } from '../constants';

class BotMaker extends Page {
   _render() {

      return <>

         <MetaTags>
            <title>WhatsApp BotMaker | Xavisoft Digital</title>
            <meta 
               name="description" 
               content="Are you tired of being asked the same questions by customers on WhatsApp? Put all the information on our platform and let users get auto responses. Coming soon!!"
            />
         </MetaTags>

         <>
         <div
            style={{
               height: 'calc(0.40 * (var(--window-height) - var(--navbar-height)))',
               backgroundColor: 'var(--primary)'
            }}
            className="vh-align"
         >
            <h1 style={{ color: 'white' }}>
               {this.props.title}
            </h1>
         </div>

         <div style={{ margin: '40px 20px' }}>
            <Grid container>

               <Hidden only={[ 'xs' ]}>
                  <Grid item sm={6}>
                     <div className='vh-align fill-container'>
                        <div 
                           style={{
                              width: '70%',
                              borderRadius: 30,
                              overflow: 'hidden'
                           }}
                        >
                           <LazyloadImage
                              src={botmakerJPG}
                              objectFit="cover"
                              aspectRatio={1}
                           />
                        </div>
                     </div>
                  </Grid>
               </Hidden>

               <Grid item xs={12} sm={6}>

                  <h2 style={{ fontSize: 60, margin: 0, }}>XAVISOFT BOTMAKER!</h2>

                  <p style={{ margin: '20px auto', fontSize: '20px', }}>
                     Are you tired of being asked the same questions by customers on WhatsApp? Put all the information on our platform and let users get auto responses.
                  </p>

                  <a href={BOTMAKER_URL}>
                     <Button variant='contained' className="primary-bg" size='large'>
                        GET STARTED 
                     </Button>
                  </a>

               </Grid>
            </Grid>
         </div>

   
            
      </>
      </>
   }
}


export default BotMaker;