
import { Button, Grid, Hidden } from '@mui/material';
import LazyloadImage from '../components/LazyloadImage';
import Component from '@xavisoft/react-component';
import Subscription from './Subscription';

class ComingSoon extends Component {

   state = {
      subscriptionModalOpen: false,
   }

   openSubscriptionModal = () => {
      return this.updateState({ subscriptionModalOpen: true });
   }

   closeSubscriptionModal = () => {
      return this.updateState({ subscriptionModalOpen: false });
   }

   render() {

      return <>
         <div
            style={{
               height: 'calc(0.40 * (var(--window-height) - var(--navbar-height)))',
               backgroundColor: 'var(--primary)'
            }}
            className="vh-align"
         >
            <h1 style={{ color: 'white' }}>
               {this.props.title}
            </h1>
         </div>

         <div style={{ margin: '40px 20px' }}>
            <Grid container>

               <Hidden only={[ 'xs' ]}>
                  <Grid item sm={6}>
                     <div className='vh-align fill-container'>
                        <div 
                           style={{
                              width: '70%',
                              borderRadius: 30,
                              overflow: 'hidden'
                           }}
                        >
                           <LazyloadImage
                              src={this.props.image}
                              objectFit="cover"
                              aspectRatio={1}
                           />
                        </div>
                     </div>
                  </Grid>
               </Hidden>

               <Grid item xs={12} sm={6}>

                  <h2 style={{ fontSize: 60, margin: 0, }}>COMING SOON!</h2>

                  <p style={{ margin: '20px auto', fontSize: '20px', }}>
                     {this.props.story}
                  </p>

                  <Button variant='contained' className="primary-bg" size='large' onClick={this.openSubscriptionModal}>
                     SUBSCRIBE 
                  </Button>

               </Grid>
            </Grid>
         </div>

         <Subscription
            open={this.state.subscriptionModalOpen}
            close={this.closeSubscriptionModal}
         />
            
      </>
   }
}


export default ComingSoon;