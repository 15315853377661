import { Grid } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import { BOTMAKER_URL } from "../constants";
import Anchor from "./Anchor";


const projects = [
   {
      title: 'WhatsApp BotMaker',
      description: "Are you tired of being asked the same questions by customers on WhatsApp? Put all the information on our platform and let users get auto responses.",
      link: BOTMAKER_URL,
      externalLink: true,
   },
   {
      title: 'Online Shop',
      description: "We are developing a state-of-the art ecommerce platform, coming very soon",
      link: '/ecommerce'
   },
   {
      title: 'ERP Solution',
      description: "We are developing a book-keeping system for small businesses",
      link: '/digital-firm',
   },
]


function OngoingProjects() {
   return <div 
      style={{ 
         // color: 'white',
         padding: '150px 0',
      }}
      className="h-align"
   >
      <div style={{ width: '100%', maxWidth: 800 }}>

         <h2 className="center-align" style={{ fontSize: 50 }}>ONGOING PROJECTS</h2>
         <Grid container spacing={4}>
            {
               projects.map(project => {

                  const LinkComponent = project.externalLink ? Anchor : Link;

                  

                  return <Grid item xs={12} sm={6} md={4} key={project.title}>
                     <div 
                        className="fill-container"
                        style={{
                           padding: '5px 20px',
                           borderLeft: '4px solid grey'
                        }}
                     >
                        <span
                           style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                              display: 'block',
                              marginBottom: 5,
                              textTransform: 'uppercase'
                           }}
                           className="primary-text"
                        >
                           {project.title}
                        </span>

                        <span 
                           style={{
                              display: 'block',
                              marginRight: 20,
                           }}
                        >
                           {project.description}
                        </span>

                        <LinkComponent
                           to={project.link}
                           href={project.link}
                           style={{
                              fontFamily: 'SF Pro Text',
                              textUnderlineOffset: 2,
                              display: 'inline-block',
                              marginTop: 10,
                              color: '#00E'
                           }}
                        >
                           Learn more
                        </LinkComponent>
                     </div>
                  </Grid>
               })
            }
         </Grid>
      </div>
   </div>
}

export default OngoingProjects;