
import Component from '@xavisoft/react-component';

class Page extends Component {

   componentDidMount() {
      window.scrollTo(0, 0)
   }

   _render() {
      return <div>Please implement <code>_render()</code></div>
   }

   render() {
      return <main>
         <div className='page'>
            {this._render()}
         </div>
      </main>
   }

}

export default Page;