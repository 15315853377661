import AboutUs from "../components/AboutUs";
import Hero from "../components/Hero";
import OngoingProjects from "../components/OngoingProjects";
import Page from "./Page";
import { MetaTags } from 'react-meta-tags'


class Home extends Page {

   _render() {
      return <>

         <MetaTags>
            <title>Home | Xavisoft Digital</title>
            <meta 
               name="description" 
               content="Xavisoft Digital is a software company based in Harare. We specialize in website, mobile app and desktop app development. We also do embedded systems"
            />
         </MetaTags>

         <Hero />
         <AboutUs />
         <OngoingProjects />
      </>
   }
}


export default Home;