
import WebIcon from '@mui/icons-material/Language';
import MobileIcon from '@mui/icons-material/PhoneIphone';
import DesktopIcon from '@mui/icons-material/DesktopMac';
import IoTIcon from '@mui/icons-material/Cloud';
import { Divider, Grid, Hidden } from '@mui/material';
import aboutGig from '../media/img/about.png';
import LazyloadImage from './LazyloadImage';

const services = [
   { name: 'Web Development', icon: WebIcon },
   { name: 'Mobile App development', icon: MobileIcon },
   { name: 'Desktop App Development', icon: DesktopIcon},
   { name: 'Internet of Things', icon: IoTIcon }
]

function AboutUs() {

   return <div>
      <div 
         style={{
            // color: 'white',
            padding: '150px 40px',
            background: '#ddd'
         }}
      >
         <Grid container>

            <Hidden only={[ 'xs', 'sm' ]}>
               <Grid item xs={12} md={6}>
                  <div className='fill-container vh-align'>
                     <LazyloadImage
                        src={aboutGig}
                        objectFit="contain"
                     />
                  </div>
               </Grid>
            </Hidden>

            <Grid item xs={12} md={6}>
               <div style={{ width: '100%', maxWidth: 400 }}>
                  <h2 style={{ fontSize: 50, margin: 5 }}>ABOUT US</h2>
                  <p style={{ fontSize: 20 }}>
                     <b>Xavisoft Digital</b> is a software company based in Harare. We offer the following services:
                  </p>

                  <div 
                     style={{
                        margin: '30px auto',
                        padding: '0px 20px',
                        borderLeft: '6px solid var(--primary)',
                     }}
                  >
                     {
                        services.map(service => {

                           const ServiceIcon = service.icon;

                           return <div className='v-align' style={{ margin: '10px auto' }}>

                              <ServiceIcon
                                 className='primary-text'
                                 style={{
                                    fontSize: 50,
                                    marginRight: 10,
                                 }}
                              />

                              <span
                                 style={{
                                    fontSize: '20px',
                                    display: 'inline-block',
                                    marginLeft: 10,
                                 }}
                              >
                                 {service.name}
                              </span>

                              <Divider />
                           </div>
                        })
                     }
                  </div>
               </div>
            </Grid>
         </Grid>
            
      </div>
   </div>
}

export default AboutUs;