/** @jsxImportSource @emotion/react */
import { css as emotionReactCss } from '@emotion/react';
import { css } from "@emotion/css";
import { Grid, Hidden } from '@mui/material';
import LazyloadImage from "./LazyloadImage";
import coderPng from '../media/img/coder.png';


const heroCssClass = css({

   backgroundSize: 'cover',
   margin: 0,
   // background: 'linear-gradient(to bottom right, #020024 0%, #090979 35%, #00d4ff 100%)',

   '& h1': {
      margin: 0,
      padding: 0,
      color: 'white'
   },
   '& h2': {
      margin: 0,
      padding: 0,
      color: '#8B949E',
      // color: 'white',
   },
   '& a:visited': {
      // color: '#0066CC',
      color: 'white'
   },
   '& a': {
      display: 'inline-block',
      margin: 10,
      textUnderlineOffset: 4,
      color: 'white',
   }
});

function createResponsiveFontSize(xs, sm, md, lg, xl) {
   return emotionReactCss`

      font-size: ${xs}px;

      @media (min-width: 600px) {
         font-size: ${sm}px;
      }

      @media (min-width: 900px) {
         font-size: ${md}px;
      }

      @media (min-width: 1200px) {
         font-size: ${lg}px;
      }

      @media (min-width: 1536px) {
         font-size: ${xl}px;
      }
   `
}

const h1Css = createResponsiveFontSize(35, 70, 70, 70);
const h2Css = createResponsiveFontSize(20, 35, 35, 35);


function Hero() {
   return <div className={`${heroCssClass} page-size`}>
      <div 
         style={{ 
            background: 'linear-gradient(to bottom, var(--primary), #0d1116, #0D1116)',
         }}>
         <div style={{ width: '100%' }}>
            <Grid container>
               <Grid item xs={12} md={6}>
                  <div 
                     style={{
                        height: 'calc(var(--window-height) - var(--navbar-height))',
                     }}
                     className="v-align"
                  >
                     <div style={{ margin: 50, maxWidth: 700 }}>
                        <h1 css={h1Css}>Xavisoft Digital</h1>
                        <h2 css={h2Css}>
                           Got any tech project/idea you want built? Talk to us and we will bring it to life!
                        </h2>

                        <a href="//wa.me/263715919598">WhatsApp</a>
                        <a href="tel:+263715919598">Call Us</a>

                     </div>
                  </div>
               </Grid>
               
               <Hidden only={[ 'xs', 'sm' ]}>
                  <Grid item xs={6}>
                     <div 
                        className="fill-container vh-align"
                     >
                        <LazyloadImage
                           src={coderPng}
                        />
                     </div>
                  </Grid>
               </Hidden>
            </Grid>
         </div>
      </div>
   </div> 
}

export default Hero;