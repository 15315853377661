
import Component from "@xavisoft/react-component";
import { HashLink as Link } from 'react-router-hash-link';
import { css } from "@emotion/css";
import { Button, Hidden } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CallIcon from '@mui/icons-material/Call';
import MobileNav from "./MobileNav";
import logo from '../media/img/logo.png';

const navbarCss = css({
   backgroundColor: 'var(--primary) !important',
   padding: 0,
   overflow: 'hidden',
   margin: 0,
   fontFamily: "'SF Pro Text', sans-serif",
   "& a": {
      textDecoration: 'none',
      margin: '15px 0px 15px 30px',
      display: 'inline-block',
   },
   position: 'fixed',
   top: 0,
   left: 0,
   width: 'var(--window-width)',
});

const links = [
   {
      caption: 'Home',
      to: '/',
   },
   {
      caption: 'Online Shop',
      to: '/ecommerce',
   },
   {
      caption: 'Digital Firm',
      to: '/digital-firm'
   },
   {
      caption: 'BotMaker',
      to: '/botmaker'
   },
]

class Navbar extends Component {

   state = {
      mobileNavOpen: false
   }

   openMobileNav = () => {
      return this.updateState({ mobileNavOpen: true })
   }

   closeMobileNav = () => {
      return this.updateState({ mobileNavOpen: false })
   }

   setDimensions = () => {
      const navbar = document.getElementById('navbar');
      
      const width = navbar.offsetWidth + 'px';
      const height = navbar.scrollHeight + 'px';

      document.documentElement.style.setProperty('--navbar-width', width);
      document.documentElement.style.setProperty('--navbar-height', height);
   }

   componentWillUnmount() {
      this.resizeOberver.disconnect();
   }

   componentDidMount() {
      const resizeOberver = new window.ResizeObserver(this.setDimensions);
      resizeOberver.observe(document.getElementById('navbar'));
      this.resizeOberver = resizeOberver;

      this.setDimensions();
   }

   render() {
      return (
      <>
         <header id="navbar" className={navbarCss}>
            <div style={{ width: 'var(--window-width)', margin: 0 }}>
               <Hidden only={[ 'xs', 'sm' ]}>
                  <div className="vh-align">
                     <Logo />
                     {
                        links.map(link => {
                           return <Link 
                              to={link.to}
                              style={{
                                 color: '#ccc',
                                 fontSize: 15,
                              }}
                           >
                              {link.caption}
                           </Link>
                        })
                     }     
                  </div>
               </Hidden>

               <Hidden only={[ 'md', 'lg', 'xl' ]}>
                  <div
                     style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                     }}
                  >
                     <div>
                        <div className="vh-align fill-container">
                           <Logo />
                        </div>
                     </div>
                     <div>
                        <div className="vh-align fill-container">
                           <a href="tel:263715919598" style={{ color: '#ccc'}}>
                              <CallIcon />
                           </a>
                     </div>
                     </div>
                     <div>
                        <div className="vh-align fill-container">
                           <Button onClick={this.openMobileNav}>
                              <MenuIcon style={{ color: '#ccc'}}/>
                           </Button>
                        </div>
                     </div>
                  </div>
               </Hidden> 
            </div>
                          
         </header>

         <MobileNav
            open={this.state.mobileNavOpen}
            close={this.closeMobileNav}
            links={links}
         />
         </>
      );
   }
}

function Logo() {
   return <a
      style={{
         display: 'flex',
         alignContent: 'center',
         justifyContent: 'center',
      }}
      href="/"
   >
      <img 
         src={logo} 
         alt=""
         style={{
            width: 50,
         }}
      />
   </a>
}

export default Navbar;



// import Component from "@xavisoft/react-component";
// import { HashLink as Link } from 'react-router-hash-link';
// import { css } from "@emotion/css";
// import { Button, Hidden } from "@mui/material";
// import MenuIcon from '@mui/icons-material/Menu';
// import CallIcon from '@mui/icons-material/Call';
// import MobileNav from "./MobileNav";

// const navbarCss = css({
//    backgroundColor: 'var(--primary) !important',
//    padding: 0,
//    overflow: 'hidden',
//    margin: 0,
//    fontFamily: "'SF Pro Text', sans-serif",
//    "& a": {
//       textDecoration: 'none',
//       margin: '15px 0px 15px 30px',
//       display: 'inline-block',
//    },
//    position: 'fixed',
//    top: 0,
//    left: 0,
//    width: 'var(--window-width)',
// });

// const links = [
//    {
//       caption: 'Home',
//       to: '/',
//    },
//    {
//       caption: 'Online Shop',
//       to: '/ecommerce',
//    },
//    {
//       caption: 'Digital Firm',
//       to: '/digital-firm'
//    },
//    {
//       caption: 'BotMaker',
//       to: '/botmaker'
//    },
// ]

// class Navbar extends Component {

//    state = {
//       mobileNavOpen: false
//    }

//    openMobileNav = () => {
//       return this.updateState({ mobileNavOpen: true })
//    }

//    closeMobileNav = () => {
//       return this.updateState({ mobileNavOpen: false })
//    }

//    setDimensions = () => {
//       const navbar = document.getElementById('navbar');
      
//       const width = navbar.offsetWidth + 'px';
//       const height = navbar.offsetHeight + 'px';

//       document.documentElement.style.setProperty('--navbar-width', width);
//       document.documentElement.style.setProperty('--navbar-height', height);
//    }

//    componentWillUnmount() {
//       this.resizeOberver.disconnect();
//    }

//    componentDidMount() {
//       const resizeOberver = new window.ResizeObserver(this.setDimensions);
//       resizeOberver.observe(document.getElementById('navbar'));
//       this.resizeOberver = resizeOberver;

//       this.setDimensions();
//    }

//    render() {
//       return (
//       <>
//          <header id="navbar" className={navbarCss}>
//             <div style={{ width: 'var(--window-width)', margin: 0 }}>
//                <Hidden only={[ 'xs', 'sm' ]}>
//                   <div className="vh-align">
//                      <Logo />
//                      {
//                         links.map(link => {
//                            return <Link 
//                               to={link.to}
//                               style={{
//                                  color: '#ccc',
//                                  fontSize: 15,
//                               }}
//                            >
//                               {link.caption}
//                            </Link>
//                         })
//                      }     
//                   </div>
//                </Hidden>

//                <Hidden only={[ 'md', 'lg', 'xl' ]}>
//                   <div
//                      style={{
//                         display: 'grid',
//                         gridTemplateColumns: 'repeat(3, 1fr)',
//                      }}
//                   >
//                      <div>
//                         <div className="vh-align fill-container">
//                            <Logo />
//                         </div>
//                      </div>
//                      <div>
//                         <div className="vh-align fill-container">
//                            <a href="tel:263715919598" style={{ color: '#ccc'}}>
//                               <CallIcon />
//                            </a>
//                      </div>
//                      </div>
//                      <div>
//                         <div className="vh-align fill-container">
//                            <Button onClick={this.openMobileNav}>
//                               <MenuIcon style={{ color: '#ccc'}}/>
//                            </Button>
//                         </div>
//                      </div>
//                   </div>
//                </Hidden> 
//             </div>
                          
//          </header>

//          <MobileNav
//             open={this.state.mobileNavOpen}
//             close={this.closeMobileNav}
//             links={links}
//          />
//          </>
//       );
//    }
// }

// function Logo() {
//    return <a
//       style={{
//          background: '#ccc',
//          aspectRatio: 1,
//          borderRadius: '50%',
//          width: 15,
//          display: 'block'
//       }}
//       href="/"
//    >{""}</a>
// }

// export default Navbar;