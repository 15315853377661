
import Page from './Page';
import ecommerceJpg from '../media/img/ecommerce.jpg';
import ComingSoon from '../components/ComingSoon';
import { MetaTags } from 'react-meta-tags';

class Ecommerce extends Page {
   _render() {

      return <>

         <MetaTags>
            <title>Online Shop | Xavisoft Digital</title>
            <meta 
               name="description" 
               content="We are building a state-of-the-art ecommerce platform, together with an efficient delivery network. Coming soon!!"
            />
         </MetaTags>

         <ComingSoon
            title="Online shop"
            story="We are building a state-of-the-art ecommerce platform, together with an efficient delivery network. Get an email when we launch"
            image={ecommerceJpg}
         />

      </>
   }
}


export default Ecommerce;